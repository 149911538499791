import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import axios from 'axios'
import { getwithheader } from '../../Api/Apis'
import { BASE_URL } from '../../URL'
import TitleComp from '../../component/TitleComp'
import moment from 'moment/moment'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

const EventRegistration = () => {
    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const handleget = async () => {
        const res = await getwithheader('event_register')
        console.log(res.data)
        setdata(res.data)
    }
    const token = localStorage.getItem("token")
    const headers = {
        Authorization: `Bearer ${token}`
    }

    const navigate = useNavigate()



    useEffect(() => {
        handleget()
    }, [])

    const columns = [


        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            field: "phone",
            headerName: "Phone",
            flex: 1,
        },

        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button    className='btn actionbtn me-3' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i></button>
                        <button className='btn actionbtn' onClick={(e) => handledelete(e, params.row._id)}><i class="fa-solid fa-trash"></i></button>
                    </>
                )
            }
        },




    ]
    const handledelete = async (e, deleid) => {
        e.preventDefault()
        setloading(true)
        let requestdata = {
            data: {
                id: deleid
            }
        }
        axios.delete(`${BASE_URL}event_register`, requestdata, { headers }).then((res) => {
            console.log(res)
            if (res.data.error == "0") {
                toast.success(res.data.message)
                handleget()
                setloading(false)
            }
        }).catch((e) => {
            toast.error(e.data.message)
            setloading(false)
        })

    }

    const handleedit = (e, item) => {
        e.preventDefault()
        navigate('/event', { state: item })
    }


    const getRowId = (row) => row._id
    return (
        <>
            <div className="col-md-12">
                <div className="text-center">
                    <TitleComp title="Event Registration" />
                </div>
            </div>
            <div className="col-md-12">
                <Box>
                    <DataGrid rows={data} columns={columns} getRowId={getRowId} />
                </Box>

            </div>
        </>
    )
}

export default EventRegistration