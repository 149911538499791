import React, { useState, useEffect } from "react";


import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { BASE_URL } from "../../URL";
import { deleteapi, getwithheader, putwithheader } from "../../Api/Apis";
import { useNavigate } from "react-router-dom";


function SubCategoryList() {

    const [loading, setloading] = useState(false)
    const navigate = useNavigate()
    let token = localStorage.getItem("paneltoken")

    const [catvalue, setcatvalue] = useState("")

    const [category, setcategory] = useState([]);
    const [subcategory, setsubcategory] = useState([])


    const headers = {
        Authorization: `Bearer ${token}`

    }



    const handlecategory = () => {
        axios.get(`${BASE_URL}category`, { headers }).then((res) => {
            console.log(res.data.data)
            setcategory(res.data.data)
        })
    }

    const handlesubcategory = () => {
        axios.get(`${BASE_URL}subcategory`, { headers }).then((res) => {
            console.log("subcategory", res.data.data)
            setsubcategory(res.data.data)
        })
    }

    useEffect(() => {
        handlecategory()
        handlesubcategory()
    }, [])





    const handleDelete = async (e, id) => {
        setloading(true)
        e.preventDefault()
        const data = await deleteapi(`deletesubcategory/${id}`, token)
        console.log("data", data)
        if (data.status == "OK") {
            toast.success(data.message)
            handlesubcategory()
            setloading(false)
        }
    }


    const handlefilter = async () => {
        let res = await getwithheader(`subcategory/${catvalue}`, token)
        console.log(res.data)
        setsubcategory(res.data)
    }

    useEffect(() => {
        handlefilter()
    }, [catvalue])

    const getRowId = (row) => row._id
    const columns = [

        {
            field: "image",
            headerName: "Image",
            flex: 1,
            renderCell: (params) => {
                return (
                    <img src={`${BASE_URL}${params.row.image[0]?.img}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }}></img>
                )
            }
        },
        {
            field: "category",
            headerName: "Category",
            flex: 1,
            renderCell: (params) => {
                return params.row.category?.name
            }
        },
        {
            field: "title",
            headerName: "Title",
            flex: 1,
        },
        {
            field: "type",
            headerName: "type",
            flex: 1,
        },
        {
            field: "description",
            headerName: "description",
            flex: 1,
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <div className="d-flex gap-2 mt-1">
                            <button className='btn bg-dark text-white' onClick={(e) => navigate('/subcategory', { state: params.row })} ><i class="fa-solid fa-pen-to-square"></i></button>
                            <button className='btn bg-dark text-white' onClick={(e) => handleDelete(e, params.row._id)}><i class="fa-solid fa-trash"></i></button>
                        </div>
                    </>
                )
            }

        }

    ]

    const handleclearfilter = () => {
        setcatvalue("")
        handlesubcategory()
    }


    return (
        <>

            <ToastContainer />
            {loading && <div className="overlay"> (<Bars
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />) </div>}

            <section className="bg-body-tertiary">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card-box shadow p-3 mb-5">
                                <div className="row gy-3">
                                    <div className="col-md-12">
                                        <div className="card-header text-center">
                                            <h5>Sub Category List</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="">
                                            <label htmlFor="">Category Filter</label>
                                            <select name="" value={catvalue} onChange={(e) => setcatvalue(e.target.value)} className="form-control form-select" id="">
                                                <option value="">Select Category</option>
                                                {category.map((item) => {
                                                    return (
                                                        <>
                                                            <option value={item._id}>{item.name}</option>

                                                        </>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <button className="btn bg-primary text-white mt-4" onClick={() => handleclearfilter()}>
                                            Clear All Filter
                                        </button>
                                    </div>
                                    <div className="col-md-12">
                                        <Box>
                                            <DataGrid
                                                rows={subcategory}
                                                columns={columns}
                                                getRowId={getRowId}
                                                pageSize={subcategory.length} // Set the page size to the total number of rows
                                                pagination={false}     // Disable pagination
                                            />
                                        </Box>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

};



export default SubCategoryList;




























