import React from 'react'

function TitleComp(props) {
    return (
        <>
            <h3 className='titlecomponent'>  {props.title}</h3>
        </>
    )
}

export default TitleComp